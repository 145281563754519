import {
  FETCH_STORE,
  FETCH_STORE_SUCCESS,
  FETCH_STORE_FAILED,
  EDIT_STORE,
} from "../store/types";

import { firebase } from "../config/configureFirebase";
import { onValue, remove, push, set } from "firebase/database";

export const fetchstore = () => (dispatch) => {
  const { storeAddRef } = firebase;

  dispatch({
    type: FETCH_STORE,
  })

  onValue(storeAddRef, snapshot => {
    if(snapshot.val()){
      let data = snapshot.val();
      const storeData = Object.keys(data).map(i => {
        data[i].id = i;
        data[i].store_address = data[i].store_address ; 
        data[i].store_name = data[i].store_name;  
        data[i].store_description = data[i].store_description;
        return data[i]; 
      })
      dispatch({
        type: FETCH_STORE_SUCCESS,
        payload: storeData
      });
    }else{
      dispatch({
        type: FETCH_STORE_FAILED,
        payload: "No Stores"
      });
    }
  });
};

export const editStore = (storeData, method) => async (dispatch) => {
  const { storeAddRef, storeEditRef } = firebase;

  dispatch({
    type: EDIT_STORE,
    payload: { storeData, method },
  });

  if (method == "Add") {
    push(storeAddRef, storeData);
  } else if (method === "Delete") {
    remove(storeEditRef(storeData.id));
  } else if (method === "Edit") {
    set(storeEditRef(storeData.id), storeData);
  }
};
