import React, { useEffect, useRef, useState } from "react";
import MaterialTable from "material-table";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import CircularLoading from "../components/CircularLoading";
import { fetchstore } from "common/src/actions/storeactions";
import { colors } from "../components/Theme/WebTheme";
import GoogleMapsAutoComplete from "components/GoogleMapsAutoComplete";
import { api } from "common";
import { Typography } from "@mui/material";
import DoneAllIcon from "@mui/icons-material/DoneAll";
import BlockIcon from "@mui/icons-material/Block";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  submit3: {
    width: "100%",
    borderRadius: 3,
    marginTop: 2,
    padding: 4,
  },
  paper: {
    width: 500,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    borderRadius: 15,
    alignItems: "center",
    justifyContent: "center",
  },
}));

export default function Stores() {
  const { t, i18n } = useTranslation();
  const settings = useSelector((state) => state.settingsdata.settings);
  const auth = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const stores = useSelector((state) => state.storesdata.stores);
  const rootRef = useRef(null);
  const [loading, setLoading] = useState(true);
  const [role, setRole] = useState(null);
  const classes = useStyles();
  const [selectedRow, setSelectedRow] = useState(null);

  useEffect(() => {
    if (auth.profile && auth.profile.usertype) {
      setRole(auth.profile.usertype);
    }
  }, [auth.profile]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        await dispatch(fetchstore());
        setLoading(false);
      } catch (error) {
        console.error("Error fetching stores:", error);
        setLoading(false);
      }
    };

    fetchData();
  }, [dispatch]);

  const handleAddStore = async (newData) => {
    try {
      await dispatch(api.editStore(JSON.parse(JSON.stringify(newData)), "Add"));
      // Optionally, you can dispatch fetchstore() again to refresh the data
    } catch (error) {
      console.error("Error adding stores:", error);
    }
  };

  const handleEditStore = async (newData, oldData) => {
    try {
      await dispatch(
        api.editStore(JSON.parse(JSON.stringify(newData)), "Edit")
      );
    } catch (error) {
      console.error("Error updating store:", error);
    }
  };

  const columns = [
    { title: "Store", field: "store_name" },
    {
      title: "Address",
      field: "store_address",
      customFilterAndSearch: (term, rowData) =>
        rowData.store_address && rowData.store_address.description
          ? rowData.store_address.description
              .toLowerCase()
              .includes(term.toLowerCase())
          : false,

      render: (rowData) => rowData.store_address.description,
      editComponent: (props) => (
        <GoogleMapsAutoComplete
          id="store_address"
          variant={"outlined"}
          placeholder={t("store_address")}
          value={props.value}
          onChange={(e) => {
            console.log(e);
            console.log(props.value);
            props.onChange(e);
          }}
        />
      ),
    },
    { title: "Store Description", field: "store_description" },
    { title: "Availability", field: "availability", editable: "never" },
  ];

  if (loading || stores === null) {
    return <CircularLoading />;
  }

  return (
    <div ref={rootRef}>
      <MaterialTable
        title="Stores"
        columns={columns}
        style={{
          direction: "ltr",
          borderRadius: "8px",
          boxShadow: "4px 4px 6px #9E9E9E",
        }}
        data={stores}
        onRowClick={(evt, selectedRow) =>
          setSelectedRow(selectedRow.tableData.id)
        }
        options={{
          exportButton: true,
          rowStyle: (rowData) => ({
            backgroundColor:
              selectedRow === rowData.tableData.id ? "#EEE" : "#FFF",
          }),
          editable: {
            backgroundColor: colors.Header_Text,
            fontSize: "0.8em",
            fontWeight: "bold ",
            // fontFamily: 'Lucida Console", "Courier New", monospace',
          },
          headerStyle: {
            position: "sticky",
            top: "0px",
            backgroundColor: colors.Header_Text_back,
            color: "#fff",
            fontSize: "0.8em",
            fontWeight: "bold",
            // fontFamily: 'Lucida Console, "Courier New", monospace',
          },
        }}
        localization={{
          body: {
            addTooltip: t("add"),
            deleteTooltip: t("delete"),
            editTooltip: t("edit"),
            emptyDataSourceMessage: t("blank_message"),
            editRow: {
              deleteText: t("delete_message"),
              cancelTooltip: t("cancel"),
              saveTooltip: t("save"),
            },
          },
          toolbar: {
            searchPlaceholder: t("search"),
            exportTitle: t("export"),
          },
          header: {
            actions: t("actions"),
          },
          pagination: {
            labelDisplayedRows: "{from}-{to} " + t("of") + " {count}",
            firstTooltip: t("first_page_tooltip"),
            previousTooltip: t("previous_page_tooltip"),
            nextTooltip: t("next_page_tooltip"),
            lastTooltip: t("last_page_tooltip"),
          },
        }}
        editable={{
          onRowAdd: (newData) =>
            new Promise(async (resolve, reject) => {
              try {
                const newDataWithAvailability = {
                  ...newData,
                  availability: "available", // Set the default availability value
                };
                await handleAddStore(newDataWithAvailability);
                resolve();
              } catch (error) {
                console.error("Error adding row:", error);
                reject();
              }
            }),
          onRowUpdate: (newData, oldData) =>
            new Promise(async (resolve, reject) => {
              try {
                await handleEditStore(newData, oldData);
                resolve();
              } catch (error) {
                console.error("Error updating row:", error);
                reject();
              }
            }),
          onRowDelete: (oldData) =>
            new Promise(async (resolve, reject) => {
              try {
                await dispatch(api.editStore({ id: oldData.id }, "Delete"));

                resolve();
              } catch (error) {
                console.error("Error deleting store:", error);
                reject();
              }
            }),
        }}
        actions={[
          (rowData) =>
            role === "admin"
              ? {
                  icon: () => (
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        flexWrap: "wrap",
                      }}
                    >
                      {rowData.availability === "available" ? (
                        <BlockIcon />
                      ) : (
                        <DoneAllIcon />
                      )}
                      <Typography variant="subtitle2">
                        {rowData.availability === "available"
                          ? t("Close")
                          : t("Open")}
                      </Typography>
                    </div>
                  ),
                  onClick: async (event, rowData) => {
                    try {
                      // Assuming you have a function to update the availability in your Redux store
                      const updatedAvailability =
                        rowData.availability === "available"
                          ? "closed"
                          : "available";

                      const newData = {
                        ...rowData,
                        availability: updatedAvailability,
                      };

                      // Dispatch an action to update only the availability in your store
                      await dispatch(api.editStore(newData, "Edit"));
                    } catch (error) {
                      console.error("Error updating availability:", error);
                    }
                  },
                }
              : null,
        ]}
      />
    </div>
  );
}
