import * as types from "../store/types"
  
  const INITIAL_STATE = {
    stores: null,
    loading: false,
    error: {
      flag: false,
      msg: null
    }
  }
  
 export const storereducer = (state = INITIAL_STATE, action) => {

  switch (action.type){
    case types.FETCH_STORE:
      return {
        ...state,
        loading: true
      };
    case types.FETCH_STORE_SUCCESS:
      return {
        ...state,
        stores: action.payload,
        loading: false
      };
    case types.FETCH_STORE_FAILED:
      return {
        ...state,
        stores: null,
        loading: false,
        error: {
          flag: false,
          msg: action.payload
        }
      };
    case types.EDIT_STORE:
      return state;
    default:
      return state;
  }

 }