export const FirebaseConfig = {
	"projectId": "hatud-e5a3b",
	"appId": "1:921291110388:web:07301832d0298f4e84eba1",
	"databaseURL": "https://hatud-e5a3b-default-rtdb.asia-southeast1.firebasedatabase.app",
	"storageBucket": "hatud-e5a3b.appspot.com",
	"locationId": "us-central",
	"apiKey": "AIzaSyBdPYz64Ubl9j0Pw3tvyjyMjzwxTacT4aY",
	"authDomain": "hatud-e5a3b.firebaseapp.com",
	"messagingSenderId": "921291110388",
	"measurementId": "G-8RWEV85GCK"
};