import React, { useState, useRef, useEffect } from "react";
import { Grid, Typography, TextField } from "@mui/material";
import GoogleMapsAutoComplete from "../components/GoogleMapsAutoComplete";
import { useDispatch } from "react-redux";
import { makeStyles } from "@mui/styles";
import { api } from "common";
import Button from "components/CustomButtons/Button.js";
import { useTranslation } from "react-i18next";
import { colors } from "../components/Theme/WebTheme";

const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      margin: theme.spacing(1),
    },
  },
  "@global": {
    body: {
      backgroundColor: theme.palette.common.white,
    },
  },
  modal: {
    display: "flex",
    padding: theme.spacing(1),
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    width: 480,
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  container: {
    marginTop: theme.spacing(1),
    backgroundColor: colors.Header,
    alignContent: "center",
    borderRadius: "8px",
    width: "70%",
  },
  container1: {
    backgroundColor: colors.LandingPage_Background,
    borderTopLeftRadius: "0px",
    borderTopRightRadius: "0px",
    borderBottomLeftRadius: "8px",
    borderBottomRightRadius: "8px",
    padding: "30px",
    width: "100%",
    top: "19px",
    boxShadow: "4px 4px 6px #9E9E9E",
  },
  title: {
    color: colors.LandingPage_Background,
    padding: "10px",
  },
  gridcontainer: {
    alignContent: "center",
  },
  items: {
    margin: 0,
    width: "100%",
  },
  input: {
    fontSize: 18,
    color: "#000",
  },
  inputdimmed: {
    fontSize: 18,
    color: "#737373",
  },
  carphoto: {
    height: "18px",
    marginRight: "10px",
  },
  carphotoRtl: {
    height: "16px",
    marginLeft: "10px",
  },
  buttonStyle: {
    margin: 0,
    width: "100%",
    height: 40,
    borderRadius: "30px",
    backgroundColor: colors.Header,
    color: "#FFF",
  },
  inputRtl: {
    "& label": {
      right: 25,
      left: "auto",
    },
    "& legend": {
      textAlign: "right",
      marginRight: 18,
    },
  },
  rightRty: {
    "& legend": {
      marginRight: 30,
    },
  },
}));

export default function AddStores(props) {
  const { t, i18n } = useTranslation();
  const isRTL = i18n.dir();
  const classes = useStyles();
  const rootRef = useRef(null);
  const [storeAddress, setStoreAddress] = useState(null);
  const [store, setStore] = useState({
    store_name: "",
    store_address: storeAddress,
    store_description: "",
    availability: "available"
  });


  const handleInputChange = (field, value) => {
    setStore((prevStore) => ({
      ...prevStore,
      [field]: value,
    }));
  };

  const clearForm = () => {
    setStore({
      store_name: "",
      store_address: null,
      store_description: "",
    });
    setStoreAddress(null)
  }

  useEffect(() => {
    // Update the store_address field in the store state when storeAddress changes
    setStore((prevStore) => ({
      ...prevStore,
      store_address: JSON.parse(JSON.stringify(storeAddress)),
    }));
  }, [storeAddress]);

  const dispatch = useDispatch();

  const handleAddStore = async () => {
    try {
      dispatch(api.editStore(store, "Add"))
        .then(() => {
          // Clear the store state after successful dispatch
          clearForm()
        })
        .catch((error) => {
          console.error("Error adding stores:", error);
        });
    } catch (error) {
      console.error("Error adding stores:", error);
    }
  };

  return (
    <div className={classes.container} ref={rootRef}>
      <Grid item xs={12} sm={12} md={8} lg={8}>
        <Grid item xs={12}>
          <Typography
            component="h1"
            variant="h5"
            className={classes.title}
            style={{ textAlign: isRTL === "rtl" ? "right" : "left" }}
          >
            {t("addstorelable")}
          </Typography>
        </Grid>
        <div className={classes.container1}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                id="store_name"
                label={t("store_name")}
                variant="outlined"
                fullWidth
                className={
                  isRTL === "rtl" ? classes.inputRtl : classes.commonInputStyle
                }
                InputProps={{
                  className: classes.input,
                  style: { textAlignLast: isRTL === "rtl" ? "end" : "start" },
                }}
                value={store.store_name}
                onChange={(e) =>
                  handleInputChange("store_name", e.target.value)
                }
              />
            </Grid>
            <Grid item xs={12}>
              <GoogleMapsAutoComplete
                id="store_address"
                variant={"outlined"}
                placeholder={t("store_address")}
                className={classes.items}
                value={storeAddress}
                onChange={(e) => {
                  setStoreAddress(e);
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                id="store_description"
                label={t("store_description")}
                variant="outlined"
                fullWidth
                multiline
                rows={4}
                className={
                  isRTL === "rtl" ? classes.inputRtl : classes.commonInputStyle
                }
                InputProps={{
                  className: classes.input,
                  style: { textAlignLast: isRTL === "rtl" ? "end" : "start" },
                }}
                value={store.store_description}
                onChange={(e) =>
                  handleInputChange("store_description", e.target.value)
                }
              />
            </Grid>

            <Grid item xs={12}>
              <Button
                size="lg"
                variant="contained"
                color="secondaryButton"
                className={classes.buttonStyle}
                onClick={handleAddStore}
              >
                {t("addstorelable")}
              </Button>
            </Grid>
          </Grid>
        </div>
      </Grid>
    </div>
  );
}
